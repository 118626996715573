import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import drop from 'lodash/drop';
import Helmet from 'react-helmet';
import BlogIntent from '../components/BlogIntent';
import FeaturedPost from '../components/FeaturedPost';
import AdvertisedPost from '../components/AdvertisedPost';
import Layout from '../components/layout/Layout';
import Subscribe from '../components/Subscribe';
import styled from 'styled-components';
import settings from '../utils/settings'

// global css
import './index.css'

const {
  breakpoints: { medium },
} = settings

const AdvertisedPosts = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const HomeSubscribe = styled(Subscribe)`
  margin-top: 100px;
  margin-bottom: 150px;

  @media (min-width: ${medium.width}) {
    margin-top: 220px;
    margin-bottom: 150px;
  }
`;

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    let posts = get(this, 'props.data.allMarkdownRemark.edges')
    // first post is used for the featured post
    const featuredPost = posts.filter((post, index) => { return index === 0 });
    posts = drop(posts, 1);

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          style={[{
            "cssText": `
              html { background: #082333; }
            `
          }]}
        >
          <meta name='description' content={siteDescription} />
          <title>{siteTitle}</title>
        </Helmet>
        <BlogIntent />
        <FeaturedPost post={featuredPost[0]} />
        <HomeSubscribe />
        <AdvertisedPosts>
          {posts.map(({ node }) => (
            <AdvertisedPost
              post={node}
              key={node.fields.slug}
            />
          ))}
        </AdvertisedPosts>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            heroBackgroundTint
            attachments {
              publicURL
            }
          }
        }
      }
    }
  }
`
