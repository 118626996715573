import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colorScheme from '../utils/colorScheme';

const ButtonWrapper = styled.button`
  background: ${props => props.type === 'primary' ? colorScheme.highlight[props.color].dark : colorScheme.highlight[props.color].light};
  box-shadow: ${props => props.type === 'primary' ? `8px 8px 0px ${colorScheme.highlight[props.color].light}` : `inset 4px 4px 0 #fff, 6px 6px 0 ${colorScheme.highlight[props.color].light}`};
  border: ${props => `2px solid ${colorScheme.highlight[props.color].dark}`};
  color: ${props => props.type === 'primary' ? `white` : colorScheme.highlight[props.color].dark};
  font-weight: bold;
  font-size: 18px;
  min-height: 50px;
  min-width: 150px;
  padding: 10px 20px;
  margin: 3px 10px 3px 0;
  transition: box-shadow 0.4s;

  &:hover {
    cursor: pointer;
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
`

const Button = props => {
  return (
    <ButtonWrapper onClick={props.handleClick ? props.handleClick : undefined} type={props.type} color={props.color}>
      {props.children}
    </ButtonWrapper>
  )
}

export default Button;