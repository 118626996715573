import React from 'react'
import styled from 'styled-components';
import colorScheme from '../utils/colorScheme';
import settings from '../utils/settings'

const {
  breakpoints: { medium },
} = settings

const SubscribeWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  text-align: center;

  @media (min-width: ${medium.width}) {
    grid-column-start: 2;
    grid-column-end: 6;
  }
`;

const InputWrapper = styled.div`
  background: ${colorScheme.base.light};
  border-radius: 6px;
  height: 64px;
  display: flex;
  align-items: center;
`;

const TransparentInput = styled.input`
  border: none;
  background: transparent;
  color: ${colorScheme.font.main};
  height: 100%;
  outline: none;
  flex: 3;
  padding: 0 20px;

  @media (min-width: ${medium.width}) {
    flex: 5;
  }
`;


const JoinButton = styled.div`
  flex: 1;
  background: ${colorScheme.base.dark};
  border-radius: 6px;
  height: 80%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonFont = styled.p`
  font-size: 16px;
  color: ${colorScheme.base.light};
  line-height: 16px;
  margin: 0;
  pointer-events: none;
`;

class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
    event.preventDefault();
  }

  render() {
    return <SubscribeWrapper className={this.props.className} />
    const { email } = this.state;
    return (
      <SubscribeWrapper className={this.props.className}>
        <h3>Want to be kept in the loop?</h3>
        <InputWrapper>
          <TransparentInput
            type="text"
            value={email}
            placeholder="you@email.com"
            onChange={this.handleChange}
          />
          <JoinButton>
            <ButtonFont>
              JOIN
            </ButtonFont>
          </JoinButton>
        </InputWrapper>
      </SubscribeWrapper>
    )
  }
}

export default Subscribe
