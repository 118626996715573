import React from 'react'
import styled from 'styled-components';
import settings from '../utils/settings'

const {
  breakpoints: { medium },
} = settings

const BlogIntentWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
`;

const LargeParagraph = styled.p`
  font-size: 20px;
  line-height: 1.5;
  max-width: 80%;

  @media (min-width: ${medium.width}) {
    max-width: 50%;
  }
`;
class BlogIntent extends React.Component {
  render() {
    return (
      <BlogIntentWrapper>
        <h1>Software Development</h1>
        <LargeParagraph>
          Tid bits about the wide world of web development, integrating with hardware and also
          some exploration of the future.
        </LargeParagraph>
      </BlogIntentWrapper>
    )
  }
}

export default BlogIntent
