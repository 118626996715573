import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import Button from '../components/Button'
import colorScheme from '../utils/colorScheme';
import settings from '../utils/settings'

const {
  breakpoints: { small },
} = settings

const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  background: white;
`;

const InnerWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  border: 1px solid ${colorScheme.base.dark};
  box-shadow: 5px 5px 0px 3px ${colorScheme.base.dark};

  @media (min-width: ${small.width}) {
    flex-direction: row;
    box-shadow: 10px 10px 0px 3px ${colorScheme.base.dark};
  }
`;

const HeroWrapper = styled.div`
  flex: 3;
  width: 100%;
  background: black;
`;

const HeroImage = styled.img`

  @media (min-width: ${small.width}) {
    width: 100%;
    height: 100%;
  }
`;

const HeroTitle = styled.h2`
  color: ${props => props.tint ? colorScheme.highlight[props.tint].dark : 'inherit'};
`;

const HeroDate = styled.p`
  font-size: 20px;
  color: ${colorScheme.font.grey};
`;

const FeatureDetails = styled.div`
  flex: 3;
  padding: 15px;

  @media (min-width: ${small.width}) {
    padding-left: 20px;
  }
`;

class FeaturedPost extends React.Component {
  render() {
    const { post } = this.props;
    const tint = get(post, 'node.frontmatter.heroBackgroundTint');
    const title = get(post, 'node.frontmatter.title') || '';
    const image = get(post, 'node.frontmatter.attachments[0].publicURL') || undefined;
    const date = get(post, 'node.frontmatter.date') || '';
    const excerpt = get(post, 'node.excerpt');

    const formattedDate = moment(date);

    return (
      <Wrapper>
        <InnerWrapper
          onClick={ () => navigate(`${get(this.props, 'post.node.fields.slug')}`) }
          role="link"
        >
          <HeroWrapper>
            { image &&
              <HeroImage src={image} />
            }
            { !image &&
              <HeroTitle tint={tint}>
                { title }
              </HeroTitle>
            }
          </HeroWrapper>
          <FeatureDetails>
            <HeroDate>{ formattedDate.format('Do MMMM, YYYY') }</HeroDate>
            <HeroTitle>
              { title }
            </HeroTitle>
            <p dangerouslySetInnerHTML={{ __html: excerpt }} />

            <Button color={'secondary'} type={'secondary'}>Read more</Button>
          </FeatureDetails>
        </InnerWrapper>
      </Wrapper>
    )
  }
}

FeaturedPost.propTypes = {
  post: PropTypes.object.isRequired
};

export default FeaturedPost
